import React, { useEffect } from "react";
import Layout from "../components/_App/layout";
import SEO from "../components/_App/seo";
import Navbar from "../components/_App/NavbarStyleFour";
import Footer from "../components/_App/Footer";
import ombra from "../images/ombra-telefoni.png";
import team from "../images/bg-team-prova.png";
import teamMobile from "../images/bg-team-mobile.png";

const Team = () => {
    const [isMobile, setMobile] = React.useState(false);

    const handleResize = () => {
        if (window.innerWidth < 575) {
            setMobile(true);
        } else {
            setMobile(false);
        }
    };

    React.useEffect(() => {
        window.addEventListener("resize", () => handleResize());
        handleResize();
        return () => {
            window.removeEventListener("resize", () => handleResize());
        };
    }, []);

    return (
        <Layout>
            <SEO title="Team | APPIUS - Software House & Web Agency" />

            <Navbar />

            <div
                id="teamSection"
                className="page-title-area overflow-hidden bg-team text-start position-relative"
            >
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 col-md-2">
                            <h2
                                style={{ fontSize: 32, fontWeight: "bold" }}
                                className={`text-uppercase text-light Varela ${
                                    isMobile ? "container" : ""
                                }`}
                            >
                                IL TEAM
                            </h2>
                        </div>
                        <div
                            className={`col-sm-12 col-md-10 BrinnanLight ${
                                isMobile ? "mt-4" : ""
                            }`}
                        >
                            <p
                                style={{
                                    marginTop: 10,
                                    fontSize: 19,
                                    color: "white",
                                    marginLeft: isMobile ? "7%" : "4%",
                                }}
                            >
                                Come gli strumenti di un’orchestra sinfonica,
                                tutti i giorni curiamo il nostro essere squadra
                                con ascolto, con vicinanza, con rispetto.
                                Operare in un clima di benessere ci aiuta a
                                essere persone più felici, più connesse, più
                                proiettate sul futuro. Entra a far parte di
                                Appius, visita la sezione Carriere e mettiti in
                                contatto con noi.
                            </p>
                        </div>
                    </div>
                    <div className="row">
                        <div
                            className="col-lg-12"
                            style={{ marginTop: isMobile ? "35px" : "60px" }}
                        >
                            <img
                                style={{ zIndex: 1 }}
                                src={isMobile ? teamMobile : team}
                                alt=""
                            />
                        </div>
                    </div>
                </div>
                <div className="api" />
                <img className="ombraTeam" src={ombra} alt="" />
                <div className="apiInvert" />
                <div className="phoneTeam" />
                <div className="phoneTeamOmbra" />
            </div>
            <Footer />
        </Layout>
    );
};

export default Team;
